import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import H1 from 'components/Text/Headings/H1';
import AlchLogoMark from 'containers/Companies/Search/AlchLogoMark';
import ScrollDiv from 'components/Generic/ScrollDiv';
import ProfileContent from 'components/Profile/ProfileContent';
import ProfileSection from 'components/Profile/ProfileSection';
import { HeadingMedium } from 'components/Text/Headings';
import ProfileSubSection from 'components/Profile/ProfileSubSection';
import { Text, TextMd } from 'components/Text/Body';
import FormLabel from 'components/Form/FormLabel';
import { makeValueLabelArray } from 'utils/Forms/general';
import { NonFormRadioButtonsInputField } from 'components/Form/Input/RadioButtons';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { NonFormInput } from 'components/Form/Input/RenderField';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import LoadingSpinner from 'components/LoadingSpinner';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import reducer from './reducer';
import saga from './saga';
import { sendMessage, setChatHistory } from './actions';
import { selectChatHistory, selectIsLoading } from './selectors';
import ChatHistory from './ChatHistory';
import { formatGenerateMsg } from './utils';


const AiMessagePage = ({ sendMsg, isLoading, chatBetweenUsrAndAssistant, setChat, isMobileTablet }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [reason, setReason] = useState(null);
  const [expertise, setExpertise] = useState(null);
  const [tone, setTone] = useState(null);
  const [textInput, setTextInput] = useState(null);
  const scrollbarRef = useRef(null);

  const scrollToBottom = () => {
    scrollbarRef.current?.scrollTop(scrollbarRef.current?.getScrollHeight());
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatBetweenUsrAndAssistant]);

  const ButtonWrapper = isChatOpen ? ButtonSec : ButtonPrim;

  const form = (
    <>
      <HeadingMedium className="font-weight-semibold">Hey, may I ask you a few quick questions to generate the perfect message for you?</HeadingMedium>
      <TextMd className="dsa-text-lighter-cyan mb-7">Please answer these questions so that I can generate suitable message for you</TextMd>

      <div className="pb-7">
        <FormLabel label="What's the main reason for reaching out?" className="mb-5" />
        <NonFormRadioButtonsInputField
          colSize="col col-100"
          radioClassName="justify-content-between"
          simpleValue
          value={reason}
          onChange={setReason}
          options={makeValueLabelArray(['Customer Discovery', 'Sales', 'Fundraising', 'Networking', 'Partnership/Collaboration', 'Product Feedback', 'Recruitment/Talent Acquisition', 'Coaching/Mentoring', 'Market Research', 'Pitch Feedback', 'Other'])}
          isClearable={false}
          isLabelFirst
          isUnstyled
          showCheck
        />
      </div>

      <div className="pb-7">
        <FormLabel label="Which expertise fits your startup’s current need?" className="mb-5" />
        <NonFormRadioButtonsInputField
          colSize="col col-100"
          radioClassName="justify-content-between"
          simpleValue
          value={expertise}
          onChange={setExpertise}
          options={makeValueLabelArray(['Industry Experience in Target Customer', 'Current Employer is a Prospective Customer', 'Expertise Overlaps with Startup\'s Core Area', 'Advisor/Coaching Experience', 'Connections in Relevant Industry', 'Potential Investor', 'Marketing/Branding Expertise', 'Product Development Experience', 'Other'])}
          isClearable={false}
          isLabelFirst
          isUnstyled
          showCheck
        />
      </div>

      <div>
        <FormLabel label="What tone should the message have?" className="mb-5" />
        <NonFormRadioButtonsInputField
          colSize="col col-100"
          radioClassName="justify-content-between"
          simpleValue
          value={tone}
          onChange={setTone}
          options={makeValueLabelArray(['Formal', 'Corporate', 'Casual', 'Neutral', 'Warm'])}
          isClearable={false}
          isLabelFirst
          isUnstyled
          showCheck
        />
      </div>

      <div className={isChatOpen ? '' : 'd-flex justify-content-end'}>
        <ButtonWrapper
          className={isChatOpen ? 'me-0 mt-5' : 'me-0 mt-7'}
          onClick={() => {
            const formattedGenerateMsg = formatGenerateMsg(reason, expertise, tone);
            setChat([...chatBetweenUsrAndAssistant, ['user', formattedGenerateMsg]]);
            setIsChatOpen(true);
            sendMsg(formattedGenerateMsg);
          }}
        >{isChatOpen ? 'Generate' : 'Continue'}
        </ButtonWrapper>
      </div>
    </>
  );

  if (!isChatOpen) {
    return (
      <div className="dsa-ai-message-page dsa-starter-questions dsa-force-vh">
        <ScrollDiv className="dsa-single-profile dsa-profile dsa-profile__single">
          <ProfileContent>
            <ProfileSection className={classNames('_unstyled_ d-flex align-items-center', { 'mx-5': isMobileTablet })}>
              <div><AlchLogoMark width={36} isAlchemist className="me-5" /></div>
              <H1 className="mb-0">ALCHEMIST AI MESSAGE</H1>
            </ProfileSection>
            <ProfileSection>
              <ProfileSubSection>
                {form}
              </ProfileSubSection>
            </ProfileSection>
          </ProfileContent>
        </ScrollDiv>
      </div>
    );
  }

  return (
    <div className="dsa-ai-message-page d-flex flex-row align-items-stretch">
      <div className="dsa-starter-questions dsa-force-vh mb-7">
        <ScrollDiv>
          <div className={isMobileTablet ? 'p-5' : 'p-7'}>
            {form}
          </div>
        </ScrollDiv>
      </div>
      <div className="dsa-chat">
        <ProfileSection className={classNames('dsa-title-header mx-auto _unstyled_ d-flex align-items-center', { 'my-5': isMobileTablet })}>
          <div><AlchLogoMark width={36} isAlchemist className="me-5" /></div>
          <H1 className="mb-0">ALCHEMIST AI MESSAGE</H1>
        </ProfileSection>
        <div className="dsa-force-vh">
          <ScrollDiv innerRef={scrollbarRef} className="dsa-single-profile dsa-profile dsa-profile__single">
            <ProfileSection className="_unstyled_ mt-0">
              <ChatHistory messages={chatBetweenUsrAndAssistant} />

              {isLoading && <LoadingSpinner />}
            </ProfileSection>
          </ScrollDiv>
        </div>

        <div className="dsa-user-prompt">
          <div>
            <div className="position-relative">
              <NonFormInput
                type="textarea"
                rows="1"
                colSize=""
                formGroupClassName="flex-grow-1 mr-6"
                className="w-100"
                value={textInput}
                onChange={setTextInput}
                placeholder="Customize your message..."
              />
              <ButtonPrim
                className="dsa-send-btn"
                onClick={() => {
                  setChat([...chatBetweenUsrAndAssistant, ['user', textInput]]);
                  sendMsg(textInput);
                  setTextInput('');
                }}
                disabled={((!textInput || textInput === '' || /^\s*$/.test(textInput)) && !isLoading) || isLoading}
              >
                <FontAwesomeIcon icon={faPaperPlaneTop} />
              </ButtonPrim>
            </div>

            <Text className="dsa-text-lighter-cyan text-center pt-3 pb-5 mb-0">Customize and refine the message as needed. Review for accuracy, then copy, edit, and send confidently.</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

AiMessagePage.propTypes = {
  isLoading: PropTypes.bool,
  sendMsg: PropTypes.func,
  chatBetweenUsrAndAssistant: PropTypes.array,
  setChat: PropTypes.func,
  isMobileTablet: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  chatBetweenUsrAndAssistant: selectChatHistory,
});

const mapDispatchToProps = (dispatch) => ({
  sendMsg: (msg) => dispatch(sendMessage(msg)),
  setChat: (chatHistory) => dispatch(setChatHistory(chatHistory)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'aiMessage', saga });
const withReducer = injectReducer({ key: 'aiMessage', reducer });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  ResponsiveHoC
)(AiMessagePage);
