import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import RestoreAccess from 'containers/RegisterProfile/RestoreAccess';
import Admin from 'containers/Admin/Loadable';
import AlertBox from 'containers/AlertBox/Loadable';
import Analytics from 'containers/Analytics';
import RedirectFromHome from 'containers/App/RedirectFromHome';
import AppReload from 'containers/AppReload';
import ConfirmModal from 'containers/ConfirmModal/Loadable';
import SignUp from 'containers/AuthProcess/SignUp/Loadable';
import SignIn from 'containers/AuthProcess/SignInForm/Loadable';
import ResetPassword from 'containers/AuthProcess/ResetPassword/Loadable';
import Verify from 'containers/AuthProcess/Verify/Loadable';
import ConnectedSwitch from 'containers/BasicConnectedElements/ConnectedSwitch';
import CompaniesSearchPage from 'containers/Companies/Search/Loadable';
import CompanySinglePage from 'containers/Companies/Single/Loadable';
import CompaniesStartupFriendlyPage from 'containers/Companies/StartupFriendly/Loadable';
import ManageTeamPage from 'containers/Companies/ManageTeam/Page';
import NewSharedList from 'containers/Companies/NewSharedList/Loadable';
import SharedCompaniesLists from 'containers/Companies/SharedLists/Loadable';
import SharedCompaniesListsSinglePage from 'containers/Companies/SharedListSingle/Loadable';
import SharedListPublicPickerPage from 'containers/Companies/SharedListPublicPicker/Loadable';
import DemoDayEditProfile from 'containers/DemoDay/EditProfile/Loadable';
import DemoDayStart from 'containers/DemoDay/RegistrationStart/Loadable';
import ClassWebsiteAuth from 'containers/DemoDay/ClassWebsiteAuth/Loadable';
import DemoDayRegistration from 'containers/DemoDay/Registration/Loadable';
import DemoDaySignInPage from 'containers/DemoDay/SignInPage/Loadable';
import DemoDayStartUps from 'containers/DemoDay/StartUps/Loadable';
import DemoDayCurrentPage from 'containers/DemoDay/DemoDayCurrentPage';
import DemoDayAttendees from 'containers/DemoDay/DdAttendees/Loadable';
import IfsRegistration from 'containers/InvestorFeedbackSummit/Registration/Loadable';
import IfsEditProfile from 'containers/InvestorFeedbackSummit/EditProfile/Loadable';
import IfsRegistrationStart from 'containers/InvestorFeedbackSummit/RegistrationStart/Loadable';
import IFSInvestorAttendees from 'containers/InvestorFeedbackSummit/InvestorAttendees/Loadable';
import IfsFounderAgenda from 'containers/InvestorFeedbackSummit/FounderAgenda/Loadable';
import IfsInvestorAgenda from 'containers/InvestorFeedbackSummit/InvestorAgenda/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import GdprConfirm from 'containers/Gdpr/Confirm';
import DocsModal from 'containers/Gdpr/DocsModal';
import GdprFaq from 'containers/Gdpr/Faq';
import GdprDocPage from 'containers/Gdpr/DocsModal/LoadablePage';
import GdprManagement from 'containers/Gdpr/GdprManagement/Loadable';
import LegalDocuments from 'containers/Gdpr/LegalDocuments';
import LmsEmbedded from 'containers/Lms/LmsEmbedded';
import LostConnectionOverlay from 'containers/LostConnectionOverlay';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Onboarding from 'containers/Onboarding/Loadable';
import OAuthLanding from 'containers/OAuthLanding';
import VaultIntro from 'containers/Onboarding/VaultIntro';
import VaultIntroInvestors from 'containers/Onboarding/VaultIntroInvestors';
import AddAccount from 'containers/People/AddAccount/Loadable';
import AddPartner from 'containers/People/AddPartner/Loadable';
import AddContact from 'containers/People/AddContact/Loadable';
import MessageAccept from 'containers/People/Connection/Accept';
import MessageDecline from 'containers/People/Connection/Decline';
import InviteAPeer from 'containers/People/InviteAPeer/Loadable';
import PeopleListsPage from 'containers/People/PeopleLists/Loadable';
import PeopleListsSinglePage from 'containers/People/PeopleListsSingle/Loadable';
import PeopleMessagesSentPage from 'containers/People/MessagesSent/Loadable';
import ClassCoachesPage from 'containers/People/ClassCoaches/Loadable';
import VerifyAccept from 'containers/People/Verify/Accept';
import VerifyDecline from 'containers/People/Verify/Decline';
import PeoplePage from 'containers/People/Search/Loadable';
import PeopleSinglePage from 'containers/People/Single/Loadable';
import RegisterProfilePage from 'containers/RegisterProfile/Loadable';
import RegisterProfileStartPage from 'containers/RegisterProfileStart/Loadable';
import ResourcesAdmin from 'containers/Resources/Admin/Loadable';
import ProgramPage from 'containers/Resources/Program/Loadable';
import ResourcesPage from 'containers/Resources/Search/Loadable';
import SneakPeekConditionsAccept from 'containers/DemoDay/ClassWebsiteInterest/Accept';
import SneakPeekConditionsDecline from 'containers/DemoDay/ClassWebsiteInterest/Decline';
import SignInPage from 'containers/SignIn';
import LMSSignInPage from 'containers/Lms/SignInPage';
import VerifyOrCancelAttendance from 'containers/InvestorFeedbackSummit/VerifyOrCancelAttendance';
import { selectIsDesktopVersion } from 'containers/MobileDesktopSwitcher/selectors';
import DesignSystem from 'designSystemDocs/Loadable';
import Avatars from 'designSystemDocs/Elements/Avatars';
import Buttons from 'designSystemDocs/Elements/Buttons';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import Perks from 'containers/Perks/Search/Loadable';
import PerkSinglePage from 'containers/Perks/Single/Loadable';
import EditSidebarItemModal from 'containers/EditSidebarItemModal';
import IfsWelcomePage from 'containers/InvestorFeedbackSummit/IfsWelcomePage';
import AdminConnectModal from 'containers/People/AdminConnectModal';
import DemoDayInterestSuccessPage from 'containers/AuthProcess/DemoDayInterestSuccessPage';
import LandscapeModal from 'components/Modals/LandscapeModal';
import ArchivedOwnLists from 'containers/People/PeopleLists/Archived/ArchivedOwnLists';
import ArchivedEventLists from 'containers/People/PeopleLists/Archived/ArchivedEventLists';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';
import AuthRoute from 'containers/Routes/AuthRouteWithErrorBoundary';
import Route from 'containers/Routes/PureRouteWithErrorBoundary';
import AdminMobileAlertModal from 'components/Modals/AdminMobileAlertModal';
import { setIsRemindersModalOpen } from 'containers/Admin/DemoDay/actions';
import MarkFloater from 'containers/Admin/InvestorFeedbackSummit/MarkFloater';
import FounderChooseModal from 'containers/FounderChooseModal';
import DesignSystemBetaIcon from 'designSystemDocs/Elements/DesignSystemBetaIcon';
import AiMessagePage from 'containers/AiMessage';

import { LP_OR_ABOVE, DD_OR_ABOVE, RESOURCE_COMPANIES, RESOURCE_PEOPLE, PERMISSION, RESOURCE_PERKS, CORP_INNOV_OR_ABOVE } from './constants';
import SideBarContainer from './SideBarContainer';
import SiteMainWrapper from './SiteMainWrapper';
import BranchWatermark from './BranchWatermark';
import CustomConsentManager from '../ConsentManager';
import saga from './saga';
import CustomIntercomLauncher from '../../components/Intercom/CustomIntercomLauncher';
import { makeSelectLocation } from './selectors';


const AppWrapper = ResponsiveHoC(({ isMobileTablet, isMobile, children }) => (
  <div className={classNames('site-wrapper', { 'is-mobile-tablet': isMobileTablet, 'is-mobile': isMobile })}>{children}</div>));

AppWrapper.propTypes = {
  isMobileTablet: PropTypes.bool,
  isMobile: PropTypes.bool,
  children: PropTypes.any,
};


const App = ({ isDesktopVersion, location, toggleRemindersModal }) => {
  const [isFromAgendaPage, setIsFromAgendaPage] = useState(location?.query?.isFS === true);

  useEffect(() => {
    if (location?.query?.isFS) {
      setIsFromAgendaPage(true);
    }
  }, [location?.query?.isFS]);

  return (
    <AppWrapper>
      <Helmet
        titleTemplate="%s | Alchemist Accelerator"
        defaultTitle="Alchemist Accelerator"
      >
        <meta name="description" content="Alchemist Accelerator app" />
        {isDesktopVersion
          ? <meta name="viewport" content="width=1280" />
          : <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        }
      </Helmet>
      <LostConnectionOverlay />
      <BranchWatermark />
      <AlertBox
        // if we need onClick inside alert in particular place,
        // please add object with the needed component's path and needed handler here.
        // also when we dispatch showAlert or displayAlert, please add isOnClickHandlerNeeded prop for particular action.
        // this is needed because several alerts can be opened inside one component, but we need onClick handler for only one of them.
        onClickHandlers={[
          {
            location: '/admin/demo-day',
            handler: () => toggleRemindersModal(true),
            linkText: 'Show Reminder Settings',
          },
        ]}
      />
      <ConnectedSwitch>
        <Route path="/demo-day/register" component={() => null} />
        <Route path="/demo-day/attendance" component={() => null} />
        <Route path="/demo-day/edit-profile/:personId" component={() => null} />
        <Route path="/ifs/register" component={() => null} />
        {!isFromAgendaPage && <Route path="/ifs/attendance" component={() => null} />}
        <Route path="/ifs/edit-profile/:personId" component={() => null} />
        <Route path="/register-profile" component={() => null} />
        <Route path="/invite-a-peer" component={() => null} />
        <Route path="/" component={SideBarContainer} />
      </ConnectedSwitch>
      <SiteMainWrapper>
        <CustomConsentManager />
        <AppReload />
        <ConfirmModal />
        <DocsModal />
        <EditSidebarItemModal />
        <AdminConnectModal />
        <LandscapeModal />
        <AdminMobileAlertModal />
        <FounderChooseModal />
        <ConnectedSwitch>
          <Route path="/welcome" exact component={VaultIntro} />
          <Route path="/welcome/investor" component={VaultIntroInvestors} />
          <Route path="/welcome/limited-partner" component={VaultIntroInvestors} />
          <Route path="/welcome/corporate-innovator" component={VaultIntroInvestors} />
          <Route path="/welcome/partner" component={(props) => <VaultIntroInvestors {...props} flow={FLOW_TYPE.onboarding} />} />
          <Route path="/signin" component={SignInPage} />
          <Route path="/signin-lms" component={LMSSignInPage} />
          <Route path="/message/accept" component={MessageAccept} />
          <Route path="/message/decline" component={MessageDecline} />
          <Route path="/verify/:verifiableType/yes" component={VerifyAccept} />
          <Route path="/verify/:verifiableType/no" component={VerifyDecline} />
          <Route path="/class-website/interest/accept" component={SneakPeekConditionsAccept} />
          <Route path="/class-website/interest/decline" component={SneakPeekConditionsDecline} />
          <AuthRoute path="/onboarding" component={Onboarding} roles={['network_onboarding', PERMISSION.onboarding, PERMISSION.founder, PERMISSION.class_coach, PERMISSION.admin, PERMISSION.partner, PERMISSION.lp, PERMISSION.corporate]} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}`} exact component={PeoplePage} roles={CORP_INNOV_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/add-account`} exact component={AddAccount} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/add-contact`} exact component={AddContact} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/add-partner`} exact component={AddPartner} />
          <Route path="/invite-a-peer" exact component={InviteAPeer} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/sent-messages`} exact component={PeopleMessagesSentPage} roles={CORP_INNOV_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/class-coaches`} exact component={ClassCoachesPage} roles={[PERMISSION.admin]} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/message/generate/:targetProfileId`} exact component={AiMessagePage} roles={[PERMISSION.admin, PERMISSION.founder]} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/lists`} exact component={PeopleListsPage} roles={LP_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/lists/archived/own`} exact component={ArchivedOwnLists} roles={LP_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/lists/archived/event`} exact component={ArchivedEventLists} roles={LP_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/lists/:listId`} component={PeopleListsSinglePage} roles={LP_OR_ABOVE} />
          <AuthRoute path={`/${RESOURCE_PEOPLE}/:personId`} component={PeopleSinglePage} roles={[...CORP_INNOV_OR_ABOVE, PERMISSION.demo_active, PERMISSION.demoday, PERMISSION.partner]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}`} exact component={CompaniesSearchPage} roles={[...CORP_INNOV_OR_ABOVE, PERMISSION.partner]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/favorites`} exact component={CompaniesSearchPage} roles={[...CORP_INNOV_OR_ABOVE, PERMISSION.partner]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/startup-friendly`} exact component={CompaniesStartupFriendlyPage} roles={[PERMISSION.admin, PERMISSION.founder, PERMISSION.class_coach]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/lists`} exact component={SharedCompaniesLists} roles={[PERMISSION.admin]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/lists/new`} exact component={NewSharedList} roles={[PERMISSION.admin]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/lists/:listId`} component={SharedCompaniesListsSinglePage} roles={[PERMISSION.admin]} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/:companyId`} exact component={CompanySinglePage} roles={[...CORP_INNOV_OR_ABOVE, PERMISSION.partner]} />
          <Route path={`/${RESOURCE_COMPANIES}/public/:companyId`} exact component={CompanySinglePage} />
          <AuthRoute path={`/${RESOURCE_COMPANIES}/:companyId/manage-team`} exact component={ManageTeamPage} />
          <AuthRoute path="/my-account/manage-team/:companyId" exact component={ManageTeamPage} />
          <AuthRoute path="/resources" exact component={ProgramPage} roles={[PERMISSION.admin, PERMISSION.founder, PERMISSION.partner, PERMISSION.class_coach]} />
          <AuthRoute path="/resources/admin/:resourceId" exact component={ResourcesAdmin} roles={[PERMISSION.admin]} />
          <AuthRoute path="/resources/admin" exact component={ResourcesAdmin} roles={[PERMISSION.admin]} />
          <AuthRoute path="/resources/videos" exact component={ResourcesPage} roles={[PERMISSION.admin, PERMISSION.founder, PERMISSION.partner, PERMISSION.class_coach]} />
          <AuthRoute path="/resources/videos/bookmarked" exact component={ResourcesPage} roles={[PERMISSION.admin, PERMISSION.founder, PERMISSION.partner, PERMISSION.class_coach]} />
          <AuthRoute path={`/resources/${RESOURCE_PERKS}`} exact component={Perks} />
          <AuthRoute path={`/resources/${RESOURCE_PERKS}/:perkId`} exact component={PerkSinglePage} />
          <AuthRoute path="/lms" component={LmsEmbedded} roles={['network_onboarding', PERMISSION.onboarding, PERMISSION.founder, PERMISSION.admin]} />
          <AuthRoute path="/admin" component={Admin} roles={[PERMISSION.admin]} />
          <AuthRoute path="/dashboard" component={Dashboard} roles={[PERMISSION.admin]} />
          <AuthRoute path="/design-system" exact component={DesignSystem} />
          <AuthRoute path="/design-system/basic-elements/buttons" exact component={Buttons} />
          <AuthRoute path="/design-system/basic-elements/avatars" exact component={Avatars} />
          <AuthRoute path="/design-system/components/beta-icon" exact component={DesignSystemBetaIcon} />
          <Route path="/share_companies/:listCode" component={SharedListPublicPickerPage} />
          <Route path="/register-profile" exact component={RegisterProfileStartPage} />
          <AuthRoute path="/register-profile/form" component={RegisterProfilePage} roles="all" />
          <Route path="/register-profile/restore-access/:email" component={RestoreAccess} />
          <Route path="/demo-day/aa/:aaClassNumber/sign-in" exact component={DemoDaySignInPage} />
          <Route path="/demo-day/ax/:aaClassNumber/sign-in" exact component={DemoDaySignInPage} />
          {/* demoday routes which should redirect to demoday login page if user is not authenticated */}
          <AuthRoute path="/demo-day/current/:pageId" isDemoDayRoute exact component={DemoDayCurrentPage} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/:aaClassNumber/companies" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/aa/:aaClassNumber/companies" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/aa/:aaClassNumber/companies/bookmarks" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/ax/:aaClassNumber/companies" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/ax/:aaClassNumber/companies/bookmarks" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/aa/alumni/:aaClassNumber/companies" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/aa/alumni/:aaClassNumber/companies/bookmarks" isDemoDayRoute exact component={DemoDayStartUps} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/aa/:aaClassNumber/attendees" exact component={DemoDayAttendees} roles={DD_OR_ABOVE} />
          <AuthRoute path="/demo-day/ax/:aaClassNumber/attendees" exact component={DemoDayAttendees} roles={DD_OR_ABOVE} />
          <Route path="/demo-day/register" exact component={DemoDayStart} />
          <Route path="/class-website/register" exact component={DemoDayStart} />
          <Route path="/dry-run/register" exact component={DemoDayStart} />
          <Route path="/customer-summit/register" exact component={DemoDayStart} />
          <Route path="/class-website/auth" exact component={ClassWebsiteAuth} />
          <Route path="/demo-day/attendance" exact component={DemoDayRegistration} />
          <Route path="/demo-day/edit-profile/:personId" component={DemoDayEditProfile} />
          <Route path="/ifs/register" exact component={IfsRegistrationStart} />
          <Route path="/ifs/attendance" exact component={IfsRegistration} />
          <Route path="/ifs/welcome" exact component={IfsWelcomePage} />
          <Route path="/ifs/welcome/:feedbackSessionDuration" exact component={IfsWelcomePage} />
          <Route path="/ifs/edit-profile/:personId" exact component={IfsEditProfile} />
          <AuthRoute path="/ifs/:ifsId/investor-attendees" exact component={IFSInvestorAttendees} roles={DD_OR_ABOVE} />
          <AuthRoute path="/ifs/:ifsId/agenda/founder/:invitationId" exact component={IfsFounderAgenda} />
          <Route path="/ifs/:ifsId/agenda/investor/:invitationCode" exact component={IfsInvestorAgenda} />
          <Route path="/ifs/meets/:action(confirm)/yes" component={VerifyOrCancelAttendance} />
          <Route path="/ifs/meets/:action(cancel)/:session(all|morning|afternoon)" component={VerifyOrCancelAttendance} />
          <Route path="/ifs/mark_as_floater" component={MarkFloater} />
          <Route path="/oauthlanding" exact component={OAuthLanding} />
          <Route path="/gdpr" exact component={LegalDocuments} />
          <Route path="/gdpr/respond" component={GdprConfirm} />
          <Route path="/gdpr/manage" exact component={GdprManagement} />
          <Route path="/gdpr/faq" exact component={GdprFaq} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/reset-password/:id/:verificationCode" exact component={ResetPassword} />
          <Route path="/email-verification/:id/:verificationCode" exact component={Verify} />
          <Route path="/gdpr/doc/:docName" exact component={GdprDocPage} />
          <Route path="/demo-day/express-interest/success" exact component={DemoDayInterestSuccessPage} />
          <Route path="/" exact component={RedirectFromHome} />
          <Route component={NotFoundPage} />
        </ConnectedSwitch>
      </SiteMainWrapper>
      {window.analytics && <Analytics />}
      {(window.analytics || location.pathname.startsWith('/demo-day/attendance')) && <CustomIntercomLauncher />}
    </AppWrapper>
  );
};

App.propTypes = {
  isDesktopVersion: PropTypes.bool,
  toggleRemindersModal: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isDesktopVersion: selectIsDesktopVersion,
  location: makeSelectLocation(),
});

const mapDispatchToProps = (dispatch) => ({
  toggleRemindersModal: (modalState) => dispatch(setIsRemindersModalOpen(modalState)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'rootSaga', saga, mode: DAEMON });

export default compose(
  withConnect,
  withSaga
)(App);
